<template>
    <div class="serialized-pagination">
        <router-link
            v-if="prevMemoPart"
            class="pagination-button prev-memo"
            :to="{ name: 'memod-reader-serialized-part', params: { writer: `${memod.writer.displayname}`, slug: memod.slug, id: memod.id, partNumber: currentPartNumber - 1 } }"
            @click.native="$emit('page-changed')"
        >
            Previous
        </router-link>
        <div class="pages">
            <router-link
                v-for="(thread, index) in memod.parts"
                :key="index"
                class="page"
                :class="{ 'active' : currentPartNumber == index + 1 }"
                :to="{ name: 'memod-reader-serialized-part', params: { writer: `${memod.writer.displayname}`, slug: memod.slug, id: memod.id, partNumber: index + 1 } }"
                @click.native="$emit('page-changed')"
            >
                {{ index + 1 }}
            </router-link>
        </div>
        <router-link
            v-if="nextMemoPart"
            class="pagination-button next-memo"
            :to="{ name: 'memod-reader-serialized-part', params: { writer: `${memod.writer.displayname}`, slug: memod.slug, id: memod.id, partNumber: currentPartNumber + 1 } }"
            @click.native="$emit('page-changed')"
        >
            Next
        </router-link>
    </div>
</template>

<script>
export default {
    name: "SerializedPagination",
    props: {
        currentPartNumber: {
            type: Number,
            required: true
        },
        memod: {
            type: Object,
            required: true
        }
    },
    computed: {
        prevMemoPart() {
            const partNumberIndex = this.currentPartNumber - 1;
            return this.memod.parts[partNumberIndex - 1];
        },
        nextMemoPart() {
            const partNumberIndex = this.currentPartNumber - 1;
            return this.memod.parts[partNumberIndex + 1];
        }
    }
}
</script>

<style lang="scss" scoped>
.serialized-pagination {
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;

    .pages {
        display: flex;
        margin-right: auto;
        margin-left: auto;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        .page {
            color: #9B9BA1;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
            text-decoration: none;
            font-size: 16px;

            &:first-child {
                margin-left: 0;
            }

            &.router-link-exact-active,
            &.active {
                color: white;
                border-color: white;
            }
        }
    }

    .pagination-button {
        font-size: 14px;
        border-radius: 30px;
        font-weight: normal;
        color: white;
        text-decoration: none;

        &.next-memo {
            margin-left: auto;
        }
    }
}
</style>
